<template>
  <div class="layout-config h-100 m-r-20">
    <Row type="flex" :gutter="10">
      <Col span="4">
        <Input
          v-model="param.query"
          placeholder="请输入研学活动名称"
          clearable
        />
      </Col>
      <Col span="4">
        <Button type="primary" @click="getData()">查询</Button>
      </Col>
    </Row>
    <Table
      border
      :columns="cols"
      :data="table.data"
      ref="table"
      class="m-t-10"
    />
    <Page
      :page="param.current"
      @on-change="getData"
      :page-size="10"
      :total="table.total"
      show-total
      class="text-r m-t-10"
    />
  </div>
</template>

<script>
import {
  getActivityByJoin,
  agreeData,
  disagreeData,
} from "@/libs/api/activity";
import { mapState } from 'vuex';
export default {
  data() {
    const _this = this;
    return {
      param: {
        current: 1,
        size: 10,
        order: "activityName",
      },
      table: {},
      cols: [
        {
          title: "序号",
          type: "index",
          key: "index",
          width: 70,
          align: "center",
        },
        { title: "研学活动名称", key: "activityName" },
        {
          title: "研学活动起止时间",
          render(h, w) {
            return h("span", w.row.startTime ? (w.row.startTime + "-" + w.row.endTime) : '');
          },
        },
        {
          title: "是否同意",
          render(h, w) {
            return h(
              "span",
              w.row.isAgree == null ? "" : w.row.isAgree ? "是" : "否"
            );
          },
        },
        {
          title: "操作",
          width: 360,
          render(h, w) {
            const btns = [];
            if(_this.isAuth) {
              btns.push(h('Button', {
                props: { type: "info", size: "small" },
                on: {
                  click() {
                    _this.$router.push({
                      path: "/config/dataManage/activity/activityResource",
                      query: { id: w.row.activityId },
                    })
                  }
                }
              }, '查看资源'))
              btns.push(h('Button', {
                props: { type: "success", size: "small" },
                on: {
                  click() {
                    _this.$router.push({
                      path: "/config/dataManage/activity/activityResourcePage",
                      query: { id: w.row.activityId },
                    })
                  }
                }
              }, '添加资源'))
            }
            btns.push(
              h(
                "Button",
                {
                  props: { type: "info", size: "small" },
                  on: {
                    click() {
                      _this.$router.push({
                        path: "/config/dataManage/activity/activityView",
                        query: { id: w.row.activityId },
                      });
                    },
                  },
                },
                "查看"
              )
            );

            btns.push(
              h(
                "Button",
                {
                  props: { type: "success", size: "small" },
                  on: {
                    click() {
                      _this.$Modal.confirm({
                        title: "同意",
                        content: `是否同意参加当前${w.row.activityName}?`,
                        onOk: () => {
                          agreeData({ activityId: w.row.activityId }).then(
                            () => {
                              _this.$Message.success("你同意参加了该活动！");
                              _this.getData();
                            }
                          );
                        },
                      });
                    },
                  },
                },
                "同意"
              )
            );
            btns.push(
              h(
                "Button",
                {
                  props: { type: "warning", size: "small" },
                  on: {
                    click() {
                      _this.$Modal.confirm({
                        title: "拒绝",
                        content: `是否拒绝参加当前${w.row.activityName}?`,
                        onOk: () => {
                          disagreeData({ activityId: w.row.activityId }).then(
                            () => {
                              _this.$Message.success("你拒绝参加了该活动！");
                              _this.getData();
                            }
                          );
                        },
                      });
                    },
                  },
                },
                "拒绝"
              )
            );
            return btns;
          },
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: state => state.user,
      roleCode: state => state.user.roleCode,
      isAuth: state => state.user.isAuth,
    })
  },
  methods: {
    // 获取数据列表
    getData(page) {
      Object.assign(this.param, { current: page ? page : 1 });
      getActivityByJoin({
        userId: this.$store.state.user.userId,
        ...this.param,
      }).then((res) => {
        res.code == "HA0200"
          ? (this.table = res)
          : this.$Message.error(res.msg);
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="less" scoped></style>
